import { FaUsers, FaUser, FaIdCard, FaBuilding, FaEnvelope, FaKey } from 'react-icons/fa';
import { HiUserCircle } from 'react-icons/hi';
import { MdSupervisorAccount } from 'react-icons/md';
import { TbHierarchy } from 'react-icons/tb';

const registerInputs = [
	{
		key: 0,
		name: 'username',
		password: false,
		optionsList: [],
		icon: <HiUserCircle size={27} />,
		placeHolder: 'Nombre de usuario',
		errorMessage: '',
		value: '',
		disabled: false,
	},
	{
		key: 1,
		name: 'lastName',
		password: false,
		optionsList: [],
		icon: <FaUser />,
		placeHolder: 'Apellido',
		errorMessage: '',
		value: '',
		disabled: false,
	},
	{
		key: 2,
		name: 'firstName',
		optionsList: [],
		password: false,
		icon: <FaUser />,
		placeHolder: 'Nombre',
		errorMessage: '',
		value: '',
		disabled: false,
	},
	{
		key: 3,
		name: 'ni',
		optionsList: [],
		password: false,
		icon: <FaIdCard />,
		placeHolder: 'NI',
		errorMessage: '',
		value: '',
		disabled: false,
	},
	{
		key: 4,
		name: 'hierarchy',
		optionsList: [],
		password: false,
		icon: <TbHierarchy size={21} />,
		placeHolder: 'Jerarquía',
		errorMessage: '',
		value: '',
		disabled: false,
	},
	{
		key: 5,
		name: 'section',
		optionsList: ['Telefonía', 'Monitoreo', 'Despacho'],
		password: false,
		icon: <FaBuilding />,
		placeHolder: 'Sección',
		errorMessage: '',
		value: '',
		disabled: false,
	},
	{
		key: 6,
		name: 'guardId',
		optionsList: [],
		password: false,
		icon: <FaUsers />,
		placeHolder: 'Guardia',
		errorMessage: '',
		value: '',
		disabled: false,
	},
	{
		key: 7,
		name: 'superior',
		optionsList: ['Si', 'No'],
		password: false,
		icon: <MdSupervisorAccount size={28} />,
		placeHolder: 'Superior',
		errorMessage: '',
		value: '',
		disabled: false,
	},
	{
		key: 8,
		name: 'email',
		optionsList: [],
		password: false,
		icon: <FaEnvelope />,
		placeHolder: 'Correo electrónico',
		errorMessage: '',
		value: '',
		disabled: false,
	},
	{
		key: 9,
		name: 'password',
		optionsList: [],
		password: true,
		icon: <FaKey />,
		placeHolder: 'Contraseña',
		errorMessage: '',
		value: '',
		disabled: false,
	},
	{
		key: 10,
		name: 'repeatPassword',
		optionsList: [],
		password: true,
		icon: <FaKey />,
		placeHolder: 'Repetir contraseña',
		errorMessage: '',
		value: '',
		disabled: false,
	},
];

export default registerInputs;
